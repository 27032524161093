/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IProductPage } from 'interfaces/product-page'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './style.module.css'
import Link from 'next/link'

interface IProps {
    product: IProductPage
    loading?: boolean
}

const descriptionRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base font-medium leading-140% first:mt-0 mt-2.5 lg:mt-4">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => <ul className="list-disc pl-7.5 lg:mt-4">{children}</ul>,
}

const FeaturedProduct = ({ product }: IProps): React.ReactElement => {
    const {
        featuredCardPhoto,
        featuredCardPhotoHover,
        featuredRefillPhoto,
        featuredCardDescription,
        productName,
        slug,
    } = product

    return (
        <div className="bg-white flex flex-col lg:flex-row rounded-2lg overflow-hidden">
            {/* image */}
            <Link href={'/products/' + slug} className="block lg:w-1/2 lg:order-2 relative group">
                {featuredCardPhoto?.url && (
                    <picture className={styles['aspect-ratio-container']}>
                        {/* webp */}
                        <source type="image/webp" srcSet={featuredCardPhoto.url + '?fm=webp&w=600'} />
                        {/* jpeg */}
                        <source type="image/jpeg" srcSet={featuredCardPhoto.url + '?fm=jpg&w=600'} />

                        <img
                            src={featuredCardPhoto.url + '?fm=jpg&w=600'}
                            height={featuredCardPhoto.height}
                            width={featuredCardPhoto.width}
                            alt={featuredCardPhoto.description || featuredCardPhoto.title}
                            loading="lazy"
                            className={`w-full h-full object-cover ${styles['aspect-ratio-element']}`}
                        />
                    </picture>
                )}

                {/* badge */}
                {featuredRefillPhoto?.url && (
                    <picture>
                        {/* svg */}
                        {featuredRefillPhoto.url.endsWith('.svg') && (
                            <source type="image/svg+xml" srcSet={featuredRefillPhoto.url} />
                        )}
                        {/* webp */}
                        <source type="image/webp" srcSet={featuredRefillPhoto.url + '?fm=webp&w=300'} />
                        {/* jpeg */}
                        <source type="image/jpeg" srcSet={featuredRefillPhoto.url + '?fm=jpg&w=300'} />

                        <img
                            src={featuredRefillPhoto.url + '?fm=jpg&w=300'}
                            height={featuredRefillPhoto.height}
                            width={featuredRefillPhoto.width}
                            alt={featuredRefillPhoto.description || featuredRefillPhoto.title}
                            loading="lazy"
                            className="h-24 w-auto absolute z-10 right-1 top-1"
                        />
                    </picture>
                )}

                {/* hover photo */}
                {featuredCardPhotoHover?.url && (
                    <picture>
                        {/* svg */}
                        {featuredCardPhotoHover.url.endsWith('.svg') && (
                            <source type="image/svg+xml" srcSet={featuredCardPhotoHover.url} />
                        )}
                        {/* webp */}
                        <source type="image/webp" srcSet={featuredCardPhotoHover.url + '?fm=webp&w=600'} />
                        {/* jpeg */}
                        <source type="image/jpeg" srcSet={featuredCardPhotoHover.url + '?fm=jpg&w=600'} />

                        <img
                            src={featuredCardPhotoHover.url + '?fm=jpg&w=600'}
                            height={featuredCardPhotoHover.height}
                            width={featuredCardPhotoHover.width}
                            alt={featuredCardPhotoHover.description || featuredCardPhotoHover.title}
                            loading="lazy"
                            className="hidden lg:block absolute top-0 left-0 h-full w-full object-cover opacity-0 group-hover:opacity-100 duration-300"
                        />
                    </picture>
                )}
            </Link>

            {/* details */}
            <div className="px-5 py-6 w-full lg:w-1/2 lg:order-1 flex flex-col justify-between">
                <div>
                    <Link href={'/products/' + slug} className="font-xbase lg:text-lg font-bold leading-140%">
                        {productName}
                    </Link>

                    {featuredCardDescription?.json && (
                        <ContentfulRichText
                            content={featuredCardDescription.json}
                            renderNode={descriptionRenderNode}
                            className="mt-2.5 lg:mt-6"
                            ignoreMarkdownStyles
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default FeaturedProduct
