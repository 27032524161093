import * as React from 'react'
import { IMEDICAL_REVIEW } from 'interfaces/medical-review'
import { IImage } from 'interfaces/media'
import { getFormattedDate } from 'lib/util/date'
import Link from 'next/link'
import CabinetTokenMask from 'components/cabinet-token/cabinet-token'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
interface IProps {
    review: IMEDICAL_REVIEW
    reviewedContentLabel: string | React.ReactNode
    // medicine review in medicine pdp is styled a bit differently
    isMedicineReview?: boolean
    datePrepositionLabel: string
}
const lgBreakPoint = Number(screens['lg'].replace('px', ''))

const disclaimerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xs text-black-lighter">{children}</p>,
}

const ReviewerPicture = ({ picture }: { picture: IImage }) => (
    <div className="h-full w-full overflow-hidden">
        <picture className="shrink-0">
            <source
                media={`(min-width: ${lgBreakPoint}px)`}
                type="image/webp"
                srcSet={`
                ${picture.url}?fm=webp&w=200 200w,
            `}
            />
            <source
                type="image/webp"
                srcSet={`
                ${picture.url}?fm=webp&w=100 100w,
            `}
            />
            <source
                media={`(min-width: ${lgBreakPoint}px)`}
                type="image/jpeg"
                srcSet={`
                ${picture.url}?fm=jpg&w=200 200w,
            `}
            />
            <source
                type="image/jpeg"
                srcSet={`
                ${picture.url}?fm=jpg&w=100 100w,
            `}
            />
            <img
                className="h-full w-full object-cover"
                height={picture.height}
                width={picture.width}
                src={`${picture.url}?fm=jpg&w=200`}
                alt={picture.title}
                loading="lazy"
            />
        </picture>
    </div>
)

const ContentReviewedBy = ({ review, reviewedContentLabel, datePrepositionLabel }: IProps): React.ReactElement => {
    if (!review) {
        return null
    }

    const reviewer = review.reviewer
    const reviewedAt = new Date(review?.reviewedAt)
    const dateString = getFormattedDate(reviewedAt)
    const disclaimer = review.disclaimer

    return (
        <div className="flex flex-col md:flex-row justify-between">
            <div className="flex md:w-1/2">
                {reviewer?.picture && (
                    <CabinetTokenMask wrapperClassName="!w-auto" className="!h-12.5 !w-12.5 lg:!h-15 lg:!w-15">
                        <ReviewerPicture picture={reviewer.picture} />
                    </CabinetTokenMask>
                )}
                <div className={`-mt-0.5 lg:mt-0 ${reviewer?.picture ? 'ml-4 lg:ml-6' : ''}`}>
                    <span className={`text-xbase lg:text-xl leading-120% tracking-tight`}>
                        {reviewedContentLabel}{' '}
                        {reviewer?.slug ? (
                            <Link
                                href={'/authors/' + encodeURIComponent(reviewer.slug)}
                                className="underline whitespace-nowrap"
                            >
                                {reviewer?.name}
                            </Link>
                        ) : (
                            reviewer?.name
                        )}
                        {reviewer?.title && ','} {reviewer?.title}
                    </span>
                    {dateString && (
                        <time dateTime={reviewedAt?.toLocaleDateString()} className="mt-1 text-xs block">
                            {datePrepositionLabel} {dateString}
                        </time>
                    )}
                </div>
            </div>

            {/* discalimer */}
            {disclaimer?.json && (
                <ContentfulRichText
                    className="mt-2.5 md:mt-0 md:w-1/2 md:ml-5"
                    content={disclaimer.json}
                    renderNode={disclaimerRenderNode}
                    ignoreMarkdownStyles
                />
            )}
        </div>
    )
}

export default ContentReviewedBy
