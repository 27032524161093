import * as React from 'react'
import Accordion from 'components/accordion'
import ContentfulRichText from 'components/rich-text'
import { Document } from '@contentful/rich-text-types'
import markdownStyles from './faq-markdown-styles.module.css'
import { IOrganizedFAQs } from 'interfaces/faq'

interface IProps {
    faqs: IOrganizedFAQs
    singleColumnLayout?: boolean
    categorize?: boolean
    questionClassName?: string
    answerClassName?: string
}

const Question = ({ question, className = '' }: { question: string; className?: string }) => (
    <div className={`${className || 'text-2xl text-left tracking-tight'}`}>{question}</div>
)

const RichAnswer = ({ richAnswer, className }: { richAnswer: { json: Document; links?: any }; className?: string }) => (
    <ContentfulRichText
        className={`tracking-tighter ${className || markdownStyles['markdown']}`}
        content={richAnswer.json}
        links={richAnswer?.links}
    />
)

const FAQs = ({
    faqs,
    singleColumnLayout = false,
    categorize = true,
    questionClassName = '',
    answerClassName = '',
}: IProps): React.ReactElement => {
    // If the order of categories is available, use it, if no, sort alphabetically
    const { faqs: updatedFAQs, orderedCateogories: categories = Object.keys(updatedFAQs ?? {}).sort() } = faqs

    // the total rows the faq section will have
    const totalRows = Math.ceil(categories.length / 2)

    // divide the categories into two columns
    const leftColumnCategories = categories.slice(0, totalRows)
    const rightColumnCategories = categories.slice(totalRows)

    /**
     * returns faqs of a single category
     * @param category string
     * @returns jsx
     */
    const renderFaqsOfCategory = (category: string) => (
        <figure data-cy="faq-category" key={category} className={`${categorize ? 'first:mt-0 mt-8 lg:mt-10' : ''} `}>
            {categorize && <figcaption className="font-bold uppercase text-sm">{category}</figcaption>}
            <Accordion
                // format faqs to match IAcordionItem interface
                items={updatedFAQs?.[category]
                    ?.sort((a, b) => {
                        return a.question > b.question ? -1 : a.question < b.question ? 1 : 0
                    })
                    .map(({ question, richAnswer, sys: { id } }) => ({
                        title: <Question className={questionClassName} question={question} />,
                        content: <RichAnswer className={answerClassName} richAnswer={richAnswer} />,
                        key: id,
                    }))}
            />
        </figure>
    )

    return (
        <div className="flex flex-wrap md:flex-nowrap">
            {singleColumnLayout ? (
                <div>{categories.map((category) => renderFaqsOfCategory(category))}</div>
            ) : (
                <>
                    {/* grow ensures that this column will grow to enitre available width
                if this is the only column (when there's only one category) */}
                    <div className="md:mr-2.5 lg:mr-4 grow md:flex-basis-half">
                        {leftColumnCategories.map((category) => renderFaqsOfCategory(category))}
                    </div>
                    {rightColumnCategories.length !== 0 && (
                        <div className="mt-8 md:mt-0 md:ml-2.5 lg:ml-4 md:flex-basis-half grow-0">
                            {rightColumnCategories.map((category) => renderFaqsOfCategory(category))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default FAQs
