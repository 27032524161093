import * as React from 'react'
import MinusIcon from '../icons/minus'
import PlusIcon from '../icons/plus'
import { IAccordionItem } from '../interfaces/accordion'

interface IProps {
    items: IAccordionItem[]
    // use this prop to pass custom header for the accordion
    // as a function component, it's props witll be populated
    // with activeTabs, toggleTab, and item (accordion item)
    customHeader?: React.JSXElementConstructor<any>
    bottomBorder?: boolean
    defaultActiveTabs?: string[]
}

interface IAccordionHeaderProps {
    item: IAccordionItem
    toggle: (string) => void
    isOpen: boolean
}

const AccordionHeader = ({ item, toggle, isOpen }: IAccordionHeaderProps): React.ReactElement => (
    <button
        className="w-full py-4 focus flex justify-between items-end"
        onClick={() => {
            toggle(item.key)
        }}
        aria-controls={item.key?.toString()}
    >
        {item.title}
        <div className="h-6 w-6 ml-8 rounded-full border border-gray-darker grid place-content-center shrink-0">
            {isOpen ? <MinusIcon fill="#3c3c3c" /> : <PlusIcon fill="#3c3c3c" />}
        </div>
    </button>
)

const AccordionBody = ({ children, isOpen, id }: { children: React.ReactNode; isOpen: boolean; id: string }) => {
    const [contentEl, setContentEl] = React.useState<HTMLDivElement>()

    const contentElRef = React.useCallback((node) => {
        if (node !== null) {
            setContentEl(node)
        }
    }, [])

    return (
        <div
            ref={contentElRef}
            id={id}
            className={`${isOpen ? 'max-h-[var(--height)]' : 'max-h-0'} overflow-hidden transition-max-height ease-out`}
            style={{
                ['--height' as string]: contentEl?.scrollHeight + 'px',
            }}
        >
            {children}
        </div>
    )
}

const Accordion = ({
    items,
    customHeader: CustomHeader,
    bottomBorder = true,
    defaultActiveTabs,
}: IProps): React.ReactElement => {
    const [activeTabs, setActiveTabs] = React.useState<string[]>([])
    const [, rerender] = React.useState({})

    // force rerender when window resizes
    // we do this that height of accordion updates
    // when window is resized
    // note: adding this here in parent so we don't setup
    // a lot of listeners
    React.useEffect(() => {
        let timer

        const resizeEventListener = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                rerender({})
            }, 300)
        }

        window.addEventListener('resize', resizeEventListener)

        return () => {
            window.removeEventListener('resize', resizeEventListener)
            clearTimeout(timer)
        }
    }, [])

    React.useEffect(() => {
        if (defaultActiveTabs?.length) {
            setActiveTabs((activeTabs) => {
                const activeTabsSet = new Set(activeTabs)

                for (const defaultActiveTab of defaultActiveTabs) {
                    activeTabsSet.add(defaultActiveTab)
                }
                return Array.from(activeTabsSet)
            })
        }
    }, [defaultActiveTabs])

    const toggleTab = (key: string | number) => {
        if (activeTabs.includes(key.toString())) {
            setActiveTabs(activeTabs.filter((activeTab) => activeTab !== key))
        } else {
            setActiveTabs([...activeTabs, key.toString()])
        }
    }

    return (
        <div>
            {items?.map((item) => {
                const isOpen = activeTabs.includes(item.key.toString())

                return (
                    <div className={bottomBorder ? 'border-b border-gray-darker' : ''} key={item.key}>
                        {typeof CustomHeader === 'function' ? ( // check if CustomHeader is functional component
                            <CustomHeader activeTabs={activeTabs} item={item} toggle={toggleTab} />
                        ) : (
                            <AccordionHeader isOpen={isOpen} toggle={toggleTab} item={item} />
                        )}
                        <AccordionBody isOpen={isOpen} id={item.key.toString()}>
                            {item.content}
                        </AccordionBody>
                    </div>
                )
            })}
        </div>
    )
}

export default Accordion
