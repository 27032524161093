import { IOrganizedFAQs } from 'interfaces/faq'

export function organizeFAQsByCategory(faqs: Record<string, any>[]): IOrganizedFAQs {
    const updatedFAQs = {}
    const orderedCateogories = []
    faqs.filter((faq) => !!faq).map((faq) => {
        if (updatedFAQs[faq.category]) {
            updatedFAQs[faq.category].push(faq)
        } else {
            updatedFAQs[faq.category] = [faq]
            orderedCateogories.push(faq.category)
        }
    })

    return { faqs: updatedFAQs, orderedCateogories }
}
